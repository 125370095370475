















import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/auth";
import axios, { APIResponse } from "@/plugins/axios";
import { Company } from "@/types/user/company";
import AdminUserCompanyFormFields from "@/components/admin/user/company/AdminUserCompanyFormFields.vue";
import AdminBaseItemCRUDDialog from "@/components/admin/base/item-management/AdminBaseItemCRUDDialog.vue";

/**
 * UserCompanySettings view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminUserCompanyFormFields,
        AdminBaseItemCRUDDialog,
    },
})
export default class UserCompanySettings extends Vue {
    private isCRUDDialogVisible = true;

    private company: Company = {
        id: -1,
        name: "",
        taxNumber: "",
        addresses: [],
        employees: [],
        responsibleId: -1,
        discount: 0,
    };

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        if (AuthModule.user === null) {
            this.goToPreviousPage();
            return;
        }

        // TODO remove comment when generics fixed
        // @ts-ignore
        this.company = await this.fetchUserCompany(AuthModule.user.company.id);
        this.isCRUDDialogVisible = true;
    }

    /**
     * Fetches company by id
     *
     * @param id
     *
     * @returns Promise<Company>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchCompanyById(id: number): Promise<Company> {
        const response = (
            await axios.get<APIResponse<Company>>(`/admin/company/${id}`)
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Navigate to previous page
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private goToPreviousPage() {
        this.$router.go(-1);
    }
}
